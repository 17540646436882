// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#cms-vs-headless-cms"
        }}>{`CMS vs Headless CMS`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#lets-dive-in"
        }}>{`Let's Dive In`}</a>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "#create-your-content-type"
            }}>{`Create Your Content Type`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "#add-fields"
            }}>{`Add Fields`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "#add-content"
            }}>{`Add Content`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "#retrieve-the-content"
            }}>{`Retrieve the Content`}</a></li>
        </ol>
      </li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`In this post, I will explain a `}<strong parentName="p">{`quick`}</strong>{` and `}<strong parentName="p">{`clean`}</strong>{` way to start a simple personal blog with the help of a
headless CMS.`}</p>
    <p>{`In my previous sentence, I used the words "quick" and "clean" deliberately. Here’s why: by `}<strong parentName="p">{`clean`}</strong>{`, I mean
you will be able to structure, design, and implement any coding best practices you know inside the project.
It will remain maintainable and enjoyable each time you work on it.`}</p>
    <p>{`This post isn’t the ultimate solution for saving money and time when starting a simple blog because many tools and
services exist for this in the market. However, this method is probably one of the most affordable because you’ll be able
to `}<strong parentName="p">{`store your content in the cloud for free!`}</strong></p>
    <p>{`A small confession regarding traditional CMS (those that aren’t headless): As a tech guy, and based on my
experience, I used to believe that any project using a CMS for simplicity would face issues later. People
behind these projects often had to hire expensive developers or restart the project due to complexity.`}</p>
    <p>{`The reason is simple: they chose a CMS to build a project quickly, clicking a few buttons, either due to a
lack of time or skill (or both). Each feature was added hastily, creating messy code. The maintainers are
left to clean it up later.`}</p>
    <p>{`Thus, when someone raised the idea of using a CMS for a professional project, I often advised against it.
The stack behind the blog you’re reading is a bit of `}<inlineCode parentName="p">{`Vue`}</inlineCode>{` with `}<inlineCode parentName="p">{`TypeScript`}</inlineCode>{`, and `}<del parentName="p">{`I’m using Contentful’s`}</del>{` free
plan to store my content for free.`}</p>
    <p>{`Contentful keeps my code clean by storing all text externally, allowing
me to modify the content without touching the code. This prevents any temptation to tweak the code, which
might lead to unintended changes.`}</p>
    <h2 id="cms-vs-headless-cms">
  CMS vs Headless CMS
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The main thing to decide is how you want to retrieve your content.`}</p>
    <p>{`Contentful offers two options: traditional `}<strong parentName="p">{`REST API`}</strong>{` calls or `}<strong parentName="p">{`GraphQL`}</strong>{`. Other technical aspects, such as the
stack, architecture, and UI, are entirely up to you—unlike a traditional CMS.`}</p>
    <p>{`These reasons make me favor headless CMS over traditional ones. Traditional CMS forces you to follow their
rules, while a headless CMS gives you full control over your project.`}</p>
    <h2 id="lets-dive-in">
  Let's Dive In
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`After signing up for a `}<a parentName="p" {...{
        "href": "https://www.contentful.com/get-started/"
      }}>{`Contentful free plan`}</a>{` and naming your project (space),
the next step is to shape your content model (content type). This is similar to defining a type in code.`}</p>
    <p>{`You have two options for this:`}</p>
    <ul>
      <li parentName="ul">{`From the Contentful dashboard`}</li>
      <li parentName="ul">{`By code using the Contentful CLI`}</li>
    </ul>
    <h3>{`From the Contentful Dashboard:`}</h3>
    <p>{`This is the easiest way and can be done even by non-technical people. You can involve friends, teammates, or
clients. The steps are:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Create your content type`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Add fields to it`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Add content`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Retrieve the content in your app`}</strong></li>
    </ol>
    <p>{`...and that's it !`}</p>
    <h3 id="create-your-content-type">Create Your Content Type</h3>
    <p>{`In the header, click the `}<inlineCode parentName="p">{`Content Model`}</inlineCode>{` tab`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/Add_content_type_button.png')} loading="lazy" alt="Add content type button in Contentful" description="Add content type button in Contentful interface" />
    <hr></hr>
    <p>{`then click the `}<inlineCode parentName="p">{`Add Content Type`}</inlineCode>{` button. Give your content type a name, such as "Blog."`}</p>
    <hr></hr>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/create_new_content_type.png')} loading="lazy" alt="create new content type" description="create new content type in Contentful interface" />
    <hr></hr>
    <h3 id="add-fields">Add Fields</h3>
    <p>{`Fields are the properties you’ll parse in your code. For instance, you can set:`}</p>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`title`}</inlineCode>{` (short text, max 256 characters)`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`body`}</inlineCode>{` (rich text with a WYSIWYG editor)`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`publish date`}</inlineCode>{` (date and time type)`}</li>
    </ul>
    <hr></hr>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/add_fields.png')} loading="lazy" alt="add fields" description="add fields in Contentful interface" />
    <hr></hr>
    <p>{`There are more field types available, but these are basic examples. Check below all exisiting fields.`}</p>
    <p>{`---`}<img style={{
        width: '100%'
      }} src={require('@/assets/img/post/all_fields.png')} loading="lazy" alt="all fields" description="existing fields types in Contentful interface" /></p>
    <h3 id="add-content">Add Content</h3>
    <p>{`Now comes the fun part—writing your content!`}</p>
    <p>{`Click the "Content" tab in the dashboard, select the content type, and start writing.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/content_view_empty.png')} loading="lazy" alt="content view empty" description="content view empty in Contentful interface" />
    <hr></hr>
    <p>{`Repeat this step for each post you want to add to your blog.`}</p>
    <p>{`---`}<img style={{
        width: '100%'
      }} src={require('@/assets/img/post/content_view_with_posts.png')} loading="lazy" alt="content view with posts" description="content view with posts in Contentful interface" /></p>
    <h3 id="retrieve-the-content">Retrieve the Content</h3>
    <p>{`To retrieve the content, if you are using TypeScript, you can use Contentful types, which are included with
the package you installed.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/contentful_types.png')} loading="lazy" alt="contentful types" description="contentful types" />
    <hr></hr>
    <p>{`Who know you will want to create a plugin perhaps? ^^`}</p>
    <img style={{
      width: '50%'
    }} src={require('@/assets/img/post/contentful_import_for_plugin_creation.png')} loading="lazy" alt="contentful import for plugin creation" description="contentful import for plugin creation" />
    <hr></hr>
    <p>{`To get the type definitions for your specific content type, you can use this cool npm package:
`}<inlineCode parentName="p">{`yarn add --dev contentful-typescript-codegen`}</inlineCode>{`
This will generate types based on your content model.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://github.com/intercom/contentful-typescript-codegen"
      }}>{`github.com/intercom/contentful-typescript-codegen`}</a>{`
for more details.`}</p>
    <h3>{`By Code Using the Contentful CLI`}</h3>
    <p>{`If you prefer code over a user interface, you can manage everything in Contentful with code. You can also
push changes to your Contentful space via CI/CD pipelines for professional projects.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://github.com/contentful/contentful-migration"
      }}>{`github.com/contentful/contentful-migration`}</a>{` for more information.`}</p>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The hardest part these days is filtering through the vast Web ecosystem, especially with the growth of
frontend technologies powered by open source. Some tools, like a headless CMS, can significantly reduce
development costs at an early stage.`}</p>
    <p>{`I also had the opportunity to use Contentful professionally and highly recommend it.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   